import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../shared/auth.service";
import { Helper } from "../shared/helper";
import { CommonService } from "./common.service";
import { ProfileService } from "./profile.service";


declare var $:any;

@Injectable({ providedIn: 'root' })
export class ConfigService {

    constructor(private _helper:Helper,private router: Router,private _commonService:CommonService,private config: ProfileService,private auth:AuthService){}

    _initApp():Promise<boolean>{
        return new Promise(async (resolve,rejects)=>{
            try{
                console.log('App Initialization Start....')
                await this._commonService.store_image_settings();
                await this._commonService.store_setting_details();
                await this.loadStoreData();
                console.log('App Initialization End....')
                resolve(true);                
            }catch(err){
                rejects(true);
            }
        })
    }


    loadStoreData():Promise<boolean>{
        return new Promise((resolve,rejects)=>{
            var storeData = JSON.parse(localStorage.getItem('storeData'));
            if(!storeData){
                console.log(this._helper.router.url)
                this.auth.signOut(false, false);
                resolve(true); 
                this.router.navigate(['/store/login'])    
            }
            this.config.fetch({servertoken:storeData.servertoken,store_id:storeData._id}).then(response_data=>{
                if(response_data.success){
                    this.auth.is_store_can_create_group = response_data.store_detail.delivery_details.is_store_can_create_group;
                    this.auth.is_store_can_edit_order = response_data.store_detail.delivery_details.is_store_can_edit_order;
                    this.auth.is_store_can_add_provider = response_data.store_detail.is_store_can_add_provider;
                    this.auth.is_store_can_complete_order = response_data.store_detail.is_store_can_complete_order;
                    this.auth.is_ask_estimated_time_for_ready_order = response_data.store_detail.is_ask_estimated_time_for_ready_order;
                    this.auth.is_approved = response_data.store_detail.is_approved;
                    this.auth.is_document_uploaded = response_data.store_detail.is_document_uploaded;
                    this.auth.is_email_verified = response_data.store_detail.is_email_verified;
                    this.auth.is_phone_number_verified = response_data.store_detail.is_phone_number_verified;
                    this.auth.is_provide_table_booking = response_data.store_detail.delivery_details.is_provide_table_booking;
                    this.auth.check_verification().then(()=>{
                        resolve(true);
                    })
                }else{
                    resolve(false)
                }
            })
        })
    }

}