import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { ViewsModule } from './views/views.module';
import { FakeMissingTranslationHandler, TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { LayoutContainersModule } from './containers/layout/layout.containers.module';
import { Helper } from '../app/shared/helper';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReqInterceptInterceptor } from 'src/app/interceptor/req-intercept.interceptor';
import { ResInterceptInterceptor } from 'src/app/interceptor/res-intercept.interceptor';
import { DirectiveModule } from 'src/app/directives/directive.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { PipeModule } from 'src/app/pipes/pipe.module';
import { LoaderModule } from 'src/app/components/loader/loader.module';
import { AuthGuard } from './shared/auth.guard';
import { ConfigService } from './services/config.service';
import { MessagingService } from './shared/messaging.service';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import {GoogleLoginProvider,FacebookLoginProvider} from 'angularx-social-login';
import { CONFIG } from './constants/constant';

function load(configService:ConfigService): (() => Promise<boolean>) {
  return (): Promise<boolean> => {
    return new Promise<boolean>((resolve: (a: boolean) => void): void => {
      configService._initApp().then(()=>{
        resolve(true)
      })
    });
  };
}

@NgModule({
  imports: [
    BrowserModule,
    ViewsModule,
    DirectiveModule,
    AppRoutingModule,
    LoaderModule,
		SocialLoginModule,
    LayoutContainersModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    PipeModule,
    AngularFireMessagingModule,
    AngularFireDatabaseModule,
    AngularFireModule.initializeApp(environment.firebase),
    SimpleNotificationsModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production,registrationStrategy: 'registerImmediately' })    
  ],
  declarations: [
    AppComponent,
  ],
  providers: [
    AuthGuard,
    MessagingService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
        {
          id: GoogleLoginProvider.PROVIDER_ID,
          provider: new GoogleLoginProvider(environment.googleAppId)
        },
        {
          id: FacebookLoginProvider.PROVIDER_ID,
          provider: new FacebookLoginProvider(environment.fbAppId)
        }
        ]
      } as SocialAuthServiceConfig,
      },
    Helper,
    {
      provide: APP_INITIALIZER,
      useFactory: load,
      deps: [
        ConfigService
      ],
      multi: true
    },
  {
    provide:HTTP_INTERCEPTORS,
    useClass:ReqInterceptInterceptor,
    multi:true
  },
  {
    provide:HTTP_INTERCEPTORS,
    useClass:ResInterceptInterceptor,
    multi:true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
