import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProfileService } from 'src/app/services/profile.service';
import { Helper } from 'src/app/shared/helper';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html'
})
export class ErrorComponent implements OnInit, OnDestroy {
  adminRoot = environment.adminRoot;

  constructor(private _profileService: ProfileService,
              private _helper: Helper) { }

  ngOnInit(): void {
    document.body.classList.add('background');
  }

  ngOnDestroy(): void {
    document.body.classList.remove('background');
  }

  CheckStoreLogin(){
    this._profileService.fetch().then(data => {
      console.log(data.success)
      if (!data.success) {
        this._helper.router.navigate([`/store`])
      }
    });
  }

}
