export const environment = {
  production: false,
  adminRoot: '/app',
  dashboardUrl: 'http://13.235.27.173',
  subHiddenBreakpoint: 1440,
  menuHiddenBreakpoint: 768,
  defaultColor: 'light.blueyale',
  // socketUrl: 'http://localhost:8000/',
  // apiUrl: 'http://localhost:8000/v3',
  // imageUrl: 'http://localhost:8000/',
  // apiUrl: 'https://edelivery.appemporio.net/v3',
  // imageUrl: 'https://edelivery.appemporio.net/',
  // socketUrl: 'http://localhost:8000/',
  socketUrl: 'https://api.liefergong.com/',
  apiUrl: 'https://api.liefergong.com/v3',
  // imageUrl: 'http://192.168.0.160:8000/',
  // socketUrl: 'https://apiedeliverynew.appemporio.net',
  // apiUrl: 'https://apiedeliverynew.appemporio.net/v3',
  imageUrl: 'https://api.liefergong.com/',
  // imageUrl: 'http://localhost:8000/',
  fbAppId : '1482336665179472',
  isAuthGuardActive: true,
  googleAppId : '1062107977876-2ch5ulrgsv7p38qinc68v7rl73kv57fd.apps.googleusercontent.com',
  firebase: {
    apiKey: "AIzaSyBp50YeJnKQituM-Fvxcxh3blAG13L1RjE",
    authDomain: "liefergong-e-delivery.firebaseapp.com",
    databaseURL: "https://liefergong-e-delivery-default-rtdb.firebaseio.com",
    projectId: "liefergong-e-delivery",
    storageBucket: "liefergong-e-delivery.appspot.com",
    messagingSenderId: "915990537549",
    appId: "1:915990537549:web:6eea61e6758f82b13d4304",
    measurementId: "G-YNCVF6Y9DV"
  }
};
