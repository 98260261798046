import { Injectable } from '@angular/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { join, resolve } from 'path';
import { BehaviorSubject, from } from 'rxjs';

import { getUserRole } from 'src/app/utils/util';
import { CommonService } from '../services/common.service';
import { Helper } from './helper';
import { LangService } from './lang.service';

export interface ISignInCredentials {
  email: string;
  device_token: string;
  device_type: string;
  password: string;
}

export interface ICreateCredentials {
  email: string;
  password: string;
  displayName: string;
}

export interface IPasswordReset {
  code: string;
  newPassword: string;
}

export interface Store {
  _id: string,
  email:string,
  displayName:string,
  country_phone_code:string,
  location:string,
  servertoken:string,
  currency:string,
  is_use_item_tax: boolean
  is_tax_included: boolean
}

@Injectable({ providedIn: 'root' })
export class AuthService {

  loginStoreData:any = {_id: '', servertoken: ''};

  private loginStore = new BehaviorSubject<any>(null);
  loginSession = this.loginStore.asObservable();
  private permissions = new BehaviorSubject<any>(null);
  authPermission =  this.permissions.asObservable();
  is_main_store_login = false;
  is_store_can_add_provider = false;
  is_store_can_create_group = false;
  is_store_can_edit_order = false;
  is_store_can_complete_order = false;
  is_ask_estimated_time_for_ready_order = false;
  is_document_uploaded = false;
  is_approved = false;
  is_email_verified = false;
  is_phone_number_verified = false;
  is_verified = false;
  is_upload_store_documents = false;
  is_provide_table_booking = false;
  is_store_mail_verification = false;
  is_store_sms_verification = false;

  constructor(private helper: Helper,
    private commonService:CommonService,
    private langService:LangService,
    private notifications: NotificationsService,) { }

  signIn(credentials: ISignInCredentials) {
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.LOGIN, credentials).then(response_data=>{
      if (response_data.success) {
        this.loginStoreData = {
            email:response_data.store.email,
            displayName:response_data.store.name[0],
            _id:response_data.store._id,
            main_store_id:response_data.store._id,
            location:response_data.store.location,
            servertoken:response_data.store.server_token,
            jwt_token: response_data.store.jwt_token,
            country_phone_code:response_data.store.country_phone_code,
            country_code: response_data.country_detail.country_code,
            currency:response_data.currency,
            timezone:response_data.timezone,
            image_url: response_data.store.image_url,
            phone: response_data.store.phone,
            social_id: response_data.store.social_ids.length ? response_data.store.social_ids.length[0] : '',
            login_by: response_data.store.login_by,
            is_distance_unit_mile: response_data.country_detail.is_distance_unit_mile,
            is_use_item_tax: response_data.store.is_use_item_tax,
            is_tax_included: response_data.store.is_tax_included,
            firebase_token: response_data.firebase_token
        }
        this.is_main_store_login = true;
        localStorage.setItem('storeData',JSON.stringify(this.loginStoreData));
        localStorage.setItem('device_token',response_data.store.device_token);
        this.is_store_can_add_provider = response_data.store.is_store_can_add_provider;
        this.is_store_can_create_group = response_data.is_store_can_create_group;
        this.is_store_can_edit_order = response_data.is_store_can_edit_order;
        this.is_store_can_complete_order = response_data.store.is_store_can_complete_order;
        this.is_ask_estimated_time_for_ready_order = response_data.store.is_ask_estimated_time_for_ready_order;
        this.is_document_uploaded = response_data.store.is_document_uploaded;
        this.is_approved = response_data.store.is_approved;
        this.is_email_verified = response_data.store.is_email_verified;
        this.is_phone_number_verified = response_data.store.is_phone_number_verified;
        this.is_upload_store_documents = response_data.is_upload_store_documents
        this.is_provide_table_booking = response_data.is_provide_table_booking
        this.is_store_mail_verification= response_data.is_store_mail_verification
        this.is_store_sms_verification= response_data.is_store_sms_verification
        this.langService.init();
        this.check_verification().then(()=>{
          this.loginStore.next(this.loginStoreData)
        })
      } else {
        grecaptcha.reset()
      }
    })
  }

  check_verification(){
    return new Promise((resolve,rejects)=>{
      var setting =  this.commonService.settings
      this.is_store_mail_verification= setting.is_store_mail_verification
      this.is_store_sms_verification= setting.is_store_sms_verification
      if(this.is_phone_number_verified === true && this.is_email_verified === true){
        this.is_verified = true;
      }else if(!setting.is_store_mail_verification && !setting.is_store_sms_verification){
        this.is_verified = true;
      }else if(setting.is_store_sms_verification && setting.is_store_mail_verification){
        this.is_verified = this.is_phone_number_verified && this.is_email_verified;
      }else if(setting.is_store_sms_verification && !setting.is_store_mail_verification){
        this.is_verified = this.is_phone_number_verified;
      }else if(setting.is_store_mail_verification && !setting.is_store_sms_verification){
        this.is_verified = this.is_email_verified;
      }
      console.log('is verified' + this.is_verified)
      resolve(true)
    })
  }

  get isAdminVerificationRequired():boolean {
    return this.commonService.settings.is_store_sms_verification === true || this.commonService.settings.is_store_mail_verification === true
  }


  subStoreSignIn(credentials: ISignInCredentials){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.SUB_STORE_LOGIN, credentials).then(response_data => {
      if (response_data.success) {
        if (!response_data.sub_store.is_approved) {
          this.notifications.create(
            'Done',
            'Substore not approved please contact admin!!',
            NotificationType.Bare,
            { theClass: 'outline primary', timeOut: 6000, showProgressBar: true }
          );
        } else {
          this.loginStoreData = {
            email: response_data.sub_store.email,
            displayName: response_data.sub_store.name[0],
            _id: response_data.sub_store._id,
            main_store_id: response_data.store._id,
            location: response_data.store.location,
            country_phone_code: response_data.sub_store.country_phone_code,
            servertoken: response_data.sub_store.server_token,
            image_url: response_data.store.image_url,
            phone: response_data.store.phone,
            currency: response_data.currency,
            timezone: response_data.timezone,
            is_use_item_tax: response_data.store.is_use_item_tax
          }
          const permissions = []
          response_data.sub_store.urls.forEach((_url) => {
            if (_url.permission === 1) {
              permissions.push(_url.url)
            }
          })
          this.permissions.next(permissions)
          this.is_main_store_login = false;
          localStorage.setItem('device_token', response_data.sub_store.device_token);
          localStorage.setItem('storeData', JSON.stringify(this.loginStoreData));
          localStorage.setItem('storePermissions', JSON.stringify(permissions));
          this.is_email_verified = response_data.store.is_email_verified;
          this.is_phone_number_verified = response_data.store.is_phone_number_verified;
          this.check_verification().then(() => {
            this.loginStore.next(this.loginStoreData)
          })
        }
      } else {
        grecaptcha.reset()
      }
    })
  }

  signOut(is_reload = false, is_redirect = true) {
    return new Promise((resolve, rejects) => {
      this.onResetData();
      if(is_reload){
        window.location.reload()
      } else if(is_redirect) {
        this.helper.router.navigate(['/store/login'])
      }
    });
  }

  autologin() {
    if(!this.is_verified){
      this.signOut();
      return;
    }
    this.loginStoreData = JSON.parse(localStorage.getItem('storeData'));
    if (this.loginStoreData && this.loginStoreData.servertoken) {
      var storePermissions = JSON.parse(localStorage.getItem('storePermissions'));
      if(storePermissions){
        this.is_main_store_login = false;
        this.permissions.next(storePermissions)
      }else{
        this.is_main_store_login = true;
      }
      this.loginStore.next(this.loginStoreData);
    } else {
      console.log(this.helper.router.url)
      this.signOut(false, false)
    }
  }

  register(store: any) {
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.REGISTER, store)
  }

  check_store_location(store_data: any){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.CHECK_STORE_LOCATION, store_data)
  }

  sendPasswordEmail(json) {
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.FORGOT_PASSWORD, {"email": json.email,"type": 2, "captcha_token": json.token, "device_type": "web"})
  }

  resetPassword(credentials) {
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.NEW_PASSWORD, credentials)
  }

  otpVerify(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.FORGOT_PASSWORD_VERIFY, json)
  }

  getStore(){
    if(this.loginStoreData){
      return this.loginStoreData;
    }else{
      this.loginStoreData = JSON.parse(localStorage.getItem('storeData'));
      return this.loginStoreData;
    }
  }

  async getUser() {
    return { displayName: 'user', role: getUserRole() };
  }


  onResetData(){
    localStorage.removeItem('storeData');
    localStorage.removeItem('storePermissions');
    localStorage.removeItem('cartId');
    this.loginStoreData = undefined;
    this.loginStore.next(null);
    this.permissions.next(null);
    this.is_main_store_login = false;
    this.is_store_can_add_provider = false;
    this.is_store_can_create_group = false;
    this.is_store_can_edit_order = false;
    this.is_store_can_complete_order = false;
    this.is_ask_estimated_time_for_ready_order = false;
    this.is_document_uploaded = false;
    this.is_approved = false;
    this.is_email_verified = false;
    this.is_phone_number_verified = false;
    this.is_verified = false;
  }

}
