import { environment } from "src/environments/environment";

export var CONFIG = {
    apiUrl: environment.apiUrl,
    imageUrl: environment.imageUrl,
}

export var BASE_URLS ={
    local: 'http://localhost:4201',
    developer: 'https://storenew.appemporio.net/',
    live: 'https://storeedelivery.appemporio.net/',
    demo: 'https://storeedeliverydemo.appemporio.net/'
}

export var CONSTANT = {
    EDIT: 1,
    DELETE: 2
}